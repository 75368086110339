<template>
  <div class="container has-background-white-ter is-fullheight" style="min-height: 100vh;">
    <h1 class="title is-1 has-text-centered">{{ organisation.name }}</h1>
    <h1 class="subtitle is-uppercase has-text-centered mt-1">Good {{ message }}, Welcome!</h1>

    <CheckinSelectType v-on:type="changeType" v-if="mode=='start'"></CheckinSelectType>
    <CheckinVisitor v-on:type="changeType" v-if="mode=='visitor'"></CheckinVisitor>

    <div class="content has-text-centered" style="margin-top: auto;">
      <p class="is-size-3">{{ currentTime }}</p>
      <p class="is-size-4">{{ currentDate }}</p>
      <button v-if="mode!='start'" @click="mode='start'" class="button is-danger is-light">Restart</button>
    </div>

  </div>
</template>

<script>
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
//import api from 'helpers/apicalls';

import CheckinSelectType from './Checkins/Selector.vue'
import CheckinVisitor from './Checkins/Visitor.vue'
import { mapState } from 'vuex'




var instance = null;

export default {
  name: "home",

  computed: {

    ts() { return "x "},

    ...mapState({
  // map this.cont to store.state.count

      organisation: state => state.organisation,
      message: state => state.timeOfDay,
      currentDate: state => state.currentDate,
      currentTime: state => state.currentTime,




    })



  },

  data: function() {
    return {
      user: firebase.auth().currentUser,
      students: [],
      mode: "start",
      modeType: null,
      timeout: null,
    };
  },

  methods: {
    changeType(modeType) {
      this.mode=modeType;
      //console.log(this.mode)
      this.$store.commit("interaction")
    },



  },

  components: {
    CheckinSelectType,
    CheckinVisitor
  },

  created() {
    instance = this;

    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'interaction') {

        if (state.lastInteraction !== null) {
          if (instance.timeout !== null) {
            clearTimeout(instance.timeout)
          }
          setTimeout(()=> {
            instance.mode = "start"
          },

          120000) // 2 minutes
        }

      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },




  mounted() {
    this.$store.commit("portalStart")
  }



};
</script>

<style scoped>
.stretched-link::after{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;content:""}
</style>
