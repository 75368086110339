<template>

    <div class="columns">
      <div class="column is-one-third">
        <div class="card">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
              <a href="#" @click="$emit('type', 'student')" class="stretched-link">Student</a>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="card">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
              <a href="#" @click="$emit('type', 'staff')" class="stretched-link">Staff</a>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-one-third">
        <div class="card">
          <div class="card-content">
            <div class="content is-uppercase is-size-2 has-text-centered">
              <a href="#" @click="$emit('type', 'visitor')" class="stretched-link">Visitor</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    

</template>

<script>




export default {
  name: "visitmodeselector"
};
</script>

<style scoped>
.stretched-link::after{position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;content:""}
</style>
